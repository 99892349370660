import React, { useState, useMemo } from "react";
import { useData } from "../../dataContext";
import TranslationToggle from "../../components/TranslationToggle";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";  // Importa useTable

import "./dashboard.css";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

  const [startDate, setStartDate] = useState(startOfYear);
  const [endDate, setEndDate] = useState(currentDate);
  const [isSorted, setIsSorted] = useState(false);
  const { data } = useData(); // Asegúrate de que estás obteniendo data desde el contexto

  // Proveer un valor predeterminado para evitar errores
  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data.filter((item) => {
      const itemDate = new Date(item.discovered);
      return itemDate >= startDate && itemDate <= endDate;
    });
  }, [data, startDate, endDate]);

  const recentAttacks = useMemo(() => filteredData.slice(-10).reverse(), [filteredData]);

  const columns = useMemo(
    () => [
      {
        Header: t("post_title"),
        accessor: "post_title",
      },
      {
        Header: t("group_name"),
        accessor: "group_name",
      },
      {
        Header: t("discovered"),
        accessor: "discovered",
      },
    ],
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: recentAttacks });

  if (!Array.isArray(data)) {
    return <p>Cargando datos...</p>; // O maneja este caso como prefieras
  }

  const groupCounts = filteredData.reduce((acc, item) => {
    acc[item.group_name] = (acc[item.group_name] || 0) + 1;
    return acc;
  }, {});

  const groupList = Object.entries(groupCounts).map(([group, count]) => ({
    group,
    count,
  }));

  if (isSorted) {
    groupList.sort((a, b) => a.count - b.count);
  }

  const timelineData = filteredData.reduce((acc, item) => {
    const date = item.discovered.split(' ')[0];
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const timelineLabels = Object.keys(timelineData);
  const timelineValues = Object.values(timelineData);

  const pieOptions = {
    chart: {
      type: 'pie',
      backgroundColor: '#ffffff'
    },
    title: {
      text: t("pie_chart_title"),
      style: {
        color: '#333',
        fontSize: '18px',
      },
    },
    series: [{
      name: t("dashboard_series_name"),
      data: groupList.map(item => ({
        name: item.group,
        y: item.count,
      })),
      colors: [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
      ],
    }],
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y}',
          style: {
            fontSize: '12px',
            color: '#333',
          },
        },
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              navigate(`/group/${this.name}`);
            }
          }
        }
      }
    },
  };

  const lineOptions = {
    chart: {
      type: 'line',
      backgroundColor: '#fff', // Fondo blanco para coherencia
    },
    title: {
      text: t("timeline_chart_title"),
      style: {
        color: '#000',
        fontSize: '18px',
      },
    },
    xAxis: {
      categories: timelineLabels,
      labels: {
        style: {
          color: '#000', // Etiquetas en negro
        },
      },
    },
    yAxis: {
      title: {
        text: t("dashboard_series_name"),
        style: {
          color: '#000', // Texto en negro
        },
      },
      labels: {
        style: {
          color: '#000',
        },
      },
    },
    series: [{
      name: t("dashboard_series_name"),
      data: timelineValues,
      color: '#000', // Línea en negro
    }],
  };

  return (
    <div id='container-dashboard' className="container-fluid">
      <TranslationToggle />
      <header className="">
        <div className="dashboard-title">
          <h1>{t("dashboard_maintext")}</h1>
        </div>
      </header>

      {/* 
      <div className="row mb-4">
        <div className="col-md-6">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="form-control date-picker"
            style={{ backgroundColor: '#fff', color: '#333' }}
          />
        </div>
        <div className="col-md-6">
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="form-control"
            style={{ backgroundColor: '#fff', color: '#333' }}
          />
        </div>
      </div>
    */}
      <div className="row">
        <div className="col-lg-7 mb-4">
          <HighchartsReact highcharts={Highcharts} options={lineOptions} />
        </div>

        <div className="col-lg-5 mb-4">
          <HighchartsReact highcharts={Highcharts} options={pieOptions} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t("recent_attacks_title")}</h2>
          <hr></hr>
          <table {...getTableProps()} className="recent-attacks-table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <foooter style={{margin:'auto',textAlign:"center", display:'flex',justifyContent:'center'}}>
        <span><i>Ransomware data provided by <a class='no-deco' href="https://github.com/joshhighet/ransomwatch">RansomWatch</a> (<a class='no-deco' href="https://raw.githubusercontent.com/joshhighet/ransomwatch/main/posts.json">posts.json</a> & <a class='no-deco' href="https://raw.githubusercontent.com/joshhighet/ransomwatch/main/groups.json">groups.json</a>)</i></span>
      </foooter>
    </div>
  );
};

export default Dashboard;
