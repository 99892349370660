import React, { useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useData } from "../../dataContext";
import { useTranslation } from "react-i18next";
import TranslationToggle from "../../components/TranslationToggle";
import { useTable } from "react-table";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./groupDetails.css";

const GroupDetailsAndAttacks = () => {
  const { t } = useTranslation();
  const { grupo } = useParams();
  const { data, groups } = useData();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const group = useMemo(() => {
    if (!Array.isArray(groups) || !grupo) return null;
    return groups.find(group => group.name?.toLowerCase() === grupo.toLowerCase());
  }, [groups, grupo]);

  const groupData = useMemo(() => {
    if (!group) return [];
    return data.filter(item => item.group_name?.toLowerCase() === grupo.toLowerCase());
  }, [data, grupo, group]);

  const filteredGroupData = useMemo(() => {
    return groupData.filter(attack =>
      attack.post_title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [groupData, searchTerm]);

  const attackTimelineData = useMemo(() => {
    const timeline = {};
    groupData.forEach(attack => {
      const date = new Date(attack.discovered).toLocaleDateString();
      if (!timeline[date]) timeline[date] = 0;
      timeline[date]++;
    });
    return Object.entries(timeline).map(([date, count]) => ({ date, count }));
  }, [groupData]);

  const chartOptions = useMemo(() => ({
    chart: {
      backgroundColor: "#ffffff",
      borderRadius: 12,
    },
    title: {
      text: t("actividad_de_ataques_a_lo_largo_del_tiempo"),
    },
    xAxis: {
      categories: attackTimelineData.map(point => point.date),
      title: { text: t("fecha") },
    },
    yAxis: {
      title: { text: t("numero_de_ataques") },
    },
    series: [
      {
        name: t("ataques"),
        data: attackTimelineData.map(point => point.count),
      },
    ],credits: {
      enabled: false, // Desactiva la marca de agua
    },
  }), [attackTimelineData, t]);

  const attackColumns = useMemo(() => [
    {
      Header: t("titulo"),
      accessor: "post_title",
    },
    {
      Header: t("fecha_de_publicacion"),
      accessor: "discovered",
    },
  ], [t]);

  const {
    getTableProps: getAttacksTableProps,
    getTableBodyProps: getAttacksTableBodyProps,
    headerGroups: attacksHeaderGroups,
    rows: attacksRows,
    prepareRow: prepareAttacksRow,
  } = useTable({ columns: attackColumns, data: filteredGroupData });

  const downloadCSV = () => {
    const csvContent = [
      ["Titulo", "Fecha de Publicación"],
      ...filteredGroupData.map(({ post_title, discovered }) => [post_title, discovered]),
    ]
      .map(e => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${group.name}_ataques.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!group) {
    return <p>{t("cargando_datos_o_nombre_del_grupo_no_proporcionado")}</p>;
  }

  return (
    <div className="container group-details">
      <TranslationToggle />
      <button className="back-button" onClick={() => navigate("/")}>
        &larr; {t("volver_al_inicio")}
      </button>
      <h1>{group.name}</h1>
      <p><strong>{t("descripcion")}:</strong> {group.meta || t("no_descripcion")}</p>

      <h2>{t("ubicaciones")}</h2>
      <table className="locations-table">
        {/* Aquí renderizar ubicaciones si es necesario */}
      </table>

      <hr />
      <div className="attack-container">
        <h2>
          {t("ataques_del_grupo")}: <span>{grupo}</span>
        </h2>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />

        <div className="container-flex">
          <input
            type="text"
            className="search-input"
            placeholder={t("buscar_ataque_por_titulo")}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <button className="download-button" onClick={downloadCSV}>
            {t("descargar_datos_csv")}
          </button>
        </div>


        <table {...getAttacksTableProps()} className="attacks-table">
          <thead>
            {attacksHeaderGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getAttacksTableBodyProps()}>
            {attacksRows.map(row => {
              prepareAttacksRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupDetailsAndAttacks;
